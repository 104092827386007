import { lazy } from 'react';
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { AuthLayout } from '@/layouts/AuthLayout';
import { MainLayout } from '@/layouts/MainLayout';
import { Role } from '@/models';
import { MasterIndex } from '@/pages/MasterIndex/MasterIndex';
import MasterIndexNameList from '@/pages/MasterIndex/Names/List';

import { AuthGuard, GuestGuard } from './RouteGuards';
import { MainRoutes } from './RoutePaths';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// Auth screens
const Login = lazy(() => import('@/pages/Auth/Login'));
const ForgotPassword = lazy(() => import('@/pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('@/pages/Auth/ResetPassword'));
const EmailConfirm = lazy(() => import('@/pages/Auth/EmailConfirm'));
const CodeVerification = lazy(() => import('@/pages/Auth/CodeVerification'));

// Dashboard screens
const AgencyList = lazy(() => import('@/pages/AgencyList'));
const AgencyDetail = lazy(() => import('@/pages/AgencyDetail'));
const EmployeeList = lazy(() => import('@/pages/Employees/List'));
const EmployeeEdit = lazy(() => import('@/pages/Employees/Edit'));
const EmployeeView = lazy(() => import('@/pages/Employees/View'));
const VehicleList = lazy(() => import('@/pages/Vehicles/List'));
const VehicleEdit = lazy(() => import('@/pages/Vehicles/Edit'));
const VehicleView = lazy(() => import('@/pages/Vehicles/View'));
const EquipmentList = lazy(() => import('@/pages/Equipments/List'));
const EquipmentEdit = lazy(() => import('@/pages/Equipments/Edit'));
const EquipmentView = lazy(() => import('@/pages/Equipments/View'));
const UnitList = lazy(() => import('@/pages/Units/List'));
const UnitEdit = lazy(() => import('@/pages/Units/Edit'));
const UnitView = lazy(() => import('@/pages/Units/View'));
const UnitShiftList = lazy(() => import('@/pages/Units/UnitShifts'));
const VendorList = lazy(() => import('@/pages/Vendors/VendorList'));
const VendorView = lazy(() => import('@/pages/Vendors/VendorView'));
const VendorEdit = lazy(() => import('@/pages/Vendors/VendorEdit'));
const VendorHistory = lazy(() => import('@/pages/Vendors/VendorHistory'));
const InventoryLocationsList = lazy(
  () => import('@/pages/InventoryLocations/InventoryLocationsList'),
);
const InventoryLocationsEdit = lazy(
  () => import('@/pages/InventoryLocations/InventoryLocationsEdit'),
);
const InventoryLocationsView = lazy(
  () => import('@/pages/InventoryLocations/InventoryLocationsView'),
);

// CFS screens
const CFSList = lazy(() => import('@/pages/CFS/List'));
const ActiveCFSList = lazy(() => import('@/pages/CFS/ActiveCFSList'));
const CFSEdit = lazy(() => import('@/pages/CFS/Edit'));
const CFSHistory = lazy(() => import('@/pages/CFS/CFSHistory'));
const CFSNames = lazy(() => import('@/pages/CFS/CFSNames'));
const CFSVehicles = lazy(() => import('@/pages/CFS/CFSVehicles'));
const CFS911Info = lazy(() => import('@/pages/CFS/Information911'));
const CFSProcedure = lazy(
  () => import('@/pages/CFS/CFSRecommendedProcedure/Procedure'),
);
const NewWindowLiveInfo = lazy(
  () => import('@/pages/CFS/Edit/components/NewWindowLiveInfo'),
);

// Kases screens
const KaseList = lazy(() => import('@/pages/Kases/KaseList/KaseList'));
const RecordsList = lazy(() => import('@/pages/CFS/Records/RecordsList'));
const KaseEdit = lazy(() => import('@/pages/Kases/KaseEdit/KaseEdit'));
const KaseNames = lazy(() => import('@/pages/Kases/KaseNames/KaseNames'));
const KaseVehicles = lazy(
  () => import('@/pages/Kases/KaseVehicles/KaseVehicles'),
);
const KaseProperty = lazy(
  () => import('@/pages/Kases/KaseProperty/KaseProperty'),
);
const KaseNaratives = lazy(
  () => import('@/pages/Kases/KaseNaratives/KaseNaratives'),
);
const KaseFiles = lazy(() => import('@/pages/Kases/KaseFiles/KaseFiles'));
const KaseOverview = lazy(
  () => import('@/pages/Kases/KaseOverview/KaseOverview'),
);
const KaseCrimes = lazy(() => import('@/pages/Kases/KaseCrimes/KaseCrimes'));
const KaseArrests = lazy(() => import('@/pages/Kases/KaseArrests/KaseArrests'));
const KaseLogListScreen = lazy(
  () => import('@/pages/Kases/KaseLogs/KaseLogsListScreen'),
);

// Tasks screens
const TaskList = lazy(() => import('@/pages/Tasks/TaskList/TaskList'));
const TaskEdit = lazy(() => import('@/pages/Tasks/TaskEdit/TaskEdit'));
const TaskView = lazy(() => import('@/pages/Tasks/TaskView/TaskView'));

// Error pages
const NotFoundScreen = lazy(() => import('@/pages/NotFound'));

export const routes: RouteObject[] = [
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/auth/login" replace />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'confirm-email',
        element: <EmailConfirm />,
      },
      {
        path: 'code-verification',
        element: <CodeVerification />,
      },
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },
  {
    path: 'cfs/:cfsId',
    element: (
      <AuthGuard>
        <CFSEdit />
      </AuthGuard>
    ),
    children: [
      {
        path: 'names',
        element: <CFSNames />,
      },
      {
        path: 'vehicles',
        element: <CFSVehicles />,
      },
      {
        path: '911-information',
        element: <CFS911Info />,
      },
      {
        path: 'procedure',
        element: <CFSProcedure />,
      },
      {
        path: 'history',
        element: <CFSHistory />,
      },
    ],
  },
  {
    path: 'cfs/:cfsId/live-info',
    element: (
      <AuthGuard>
        <NewWindowLiveInfo />
      </AuthGuard>
    ),
  },
  {
    path: 'unit-shifts',
    element: (
      <AuthGuard>
        <UnitShiftList />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/cfs-active" replace />,
      },
      {
        path: 'agencies',
        handle: {
          allowedRoles: [Role.ACCOUNT_ADMIN, Role.AGENCY_ADMIN],
        },
        children: [
          {
            index: true,
            element: <AgencyList />,
          },
          {
            path: ':agencyId',
            element: <AgencyDetail />,
            children: [
              {
                index: true,
                element: <Navigate to="employees" replace />,
              },
              {
                path: 'employees',
                element: <EmployeeList />,
              },
              {
                path: 'vehicles',
                element: <VehicleList />,
              },
              {
                path: 'equipment',
                element: <EquipmentList />,
              },
              {
                path: 'units',
                element: <UnitList />,
              },
              {
                path: 'vendors',
                element: <VendorList />,
              },
              {
                path: 'inventory-locations',
                element: <InventoryLocationsList />,
                handle: {
                  roles: ['admin'],
                },
              },
            ],
          },
          {
            path: `${MainRoutes.EmployeeList}/:employeeId/view`,
            element: <EmployeeView />,
          },
          {
            path: `${MainRoutes.EmployeeList}/:employeeId`,
            element: <EmployeeEdit />,
          },
          {
            path: `${MainRoutes.VehicleList}/:vehicleId/view`,
            element: <VehicleView />,
          },
          {
            path: `${MainRoutes.VehicleList}/:vehicleId`,
            element: <VehicleEdit />,
          },
          {
            path: `${MainRoutes.EquipmentList}/:equipmentId/view`,
            element: <EquipmentView />,
          },
          {
            path: `${MainRoutes.EquipmentList}/:equipmentId`,
            element: <EquipmentEdit />,
          },
          {
            path: `${MainRoutes.UnitList}/:unitId/view`,
            element: <UnitView />,
          },
          {
            path: `${MainRoutes.UnitList}/:unitId`,
            element: <UnitEdit />,
          },
          {
            path: `${MainRoutes.VendorList}/:vendorId/view`,
            element: <VendorView />,
          },
          {
            path: `${MainRoutes.VendorList}/:vendorId`,
            element: <VendorEdit />,
          },
          {
            path: `${MainRoutes.VendorList}/:vendorId/history`,
            element: <VendorHistory />,
          },
          {
            path: `${MainRoutes.InventoryLocations}/:locationId/view`,
            element: <InventoryLocationsView />,
          },
          {
            path: `${MainRoutes.InventoryLocations}/:locationId`,
            element: <InventoryLocationsEdit />,
          },
        ],
      },
      {
        path: 'cfs',
        element: <CFSList />,
      },
      {
        path: 'cfs-active',
        element: <ActiveCFSList />,
      },
      {
        path: 'cases',
        children: [
          {
            index: true,
            element: <KaseList />,
          },
          {
            path: ':kaseId',
            element: <KaseEdit />,
            children: [
              {
                index: true,
                element: <KaseEdit />,
              },
              {
                path: 'overview',
                element: <KaseOverview />,
              },
              {
                path: 'names',
                element: <KaseNames />,
              },
              {
                path: 'vehicles',
                element: <KaseVehicles />,
              },
              {
                path: 'properties',
                element: <KaseProperty />,
              },
              {
                path: 'narrative',
                element: <KaseNaratives />,
              },
              {
                path: 'files',
                element: <KaseFiles />,
              },
              {
                path: 'crimes',
                element: <KaseCrimes />,
              },
              {
                path: 'arrests',
                element: <KaseArrests />,
              },
              {
                path: 'logs',
                element: <KaseLogListScreen />,
              },
            ],
          },
          {
            path: 'records',
            element: <RecordsList />,
          },
        ],
      },
      {
        path: 'master-index',
        element: <MasterIndex />,
        children: [
          {
            index: true,
            element: <Navigate to="names" replace />,
          },
          {
            path: 'names',
            children: [
              {
                index: true,
                element: <MasterIndexNameList />,
              },
            ],
          },
        ],
      },
      {
        path: 'tasks',
        children: [
          {
            index: true,
            element: <TaskList />,
          },

          {
            path: `${MainRoutes.TaskList}/:taskId/view`,
            element: <TaskView />,
          },
          {
            path: `${MainRoutes.TaskList}/:taskId`,
            element: <TaskEdit />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundScreen />,
  },
];

export const AppRoutes = () => {
  return <RouterProvider router={sentryCreateBrowserRouter(routes)} />;
};
