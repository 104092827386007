import { ElementRef, Ref, forwardRef, useRef, useState } from 'react';

import {
  Checkbox,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
  styled,
} from '@mui/material';

import { IcArrowDown } from '@/assets/images';
import { colors } from '@/theme/variables';

import { Input } from '../Input';
import { BaseFieldProps } from '../types';

const Root = styled('div')(() => ({
  width: '100%',
}));

const MenuItemRoot = styled(MenuItem)(() => ({
  justifyContent: 'flex-start',
  padding: 9,
  '&.multiple': {
    backgroundColor: 'transparent',
  },

  '& .MuiCheckbox-root': {
    padding: '0 8px 0 0',
  },

  '&.clear-item': {
    color: colors.error.main,
  },
}));

const SubheaderMenuItem = styled(ListSubheader)(() => ({
  fontSize: '16px',
  '& ~ .MuiMenuItem-root': {
    paddingLeft: '25px',
  },
}));

export type SelectItem = {
  label: string;
  value: string | number;
  disabled?: boolean;
  group?: string;
};

interface BaseSelectProps extends Omit<SelectProps, 'error'>, BaseFieldProps {
  items: SelectItem[];
  placeholder?: string;
  multiple?: boolean;
  value: string | number | (string | number)[];
  clearable?: boolean;
}

export const Select = forwardRef<Ref<any>, BaseSelectProps>((props, ref) => {
  const {
    items,
    label,
    value,
    error,
    multiple,
    autoWidth,
    clearable,
    ...rest
  } = props;
  const [dynamicWidth, setDynamicWidth] = useState<number>(0);
  const refContainer = useRef<ElementRef<'div'>>(null);

  const renderValue = (selectedValue: unknown) => {
    if (Array.isArray(selectedValue)) {
      const labels = items.reduce((accumulator, currentItem) => {
        if (
          'value' in currentItem &&
          selectedValue.indexOf(currentItem.value) > -1
        ) {
          accumulator.push(currentItem.label);
        }
        return accumulator;
      }, [] as string[]);

      return <>{labels.join(', ')}</>;
    } else {
      const selectedItem = items.find(
        (item) => 'value' in item && item.value === selectedValue,
      );
      return (
        <>{selectedItem && 'label' in selectedItem && selectedItem.label}</>
      );
    }
  };

  return (
    <Root ref={refContainer} className="selectWrapper">
      <MuiSelect
        input={<Input ref={ref} label={label} error={error} />}
        IconComponent={() => <IcArrowDown />}
        value={value}
        renderValue={renderValue}
        className={!value ? 'placeholder' : ''}
        multiple={multiple}
        sx={{ position: 'relative' }}
        onOpen={() => {
          if (refContainer.current && !autoWidth) {
            setDynamicWidth(refContainer?.current?.offsetWidth);
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            sx: { padding: 0, maxHeight: 450 },
          },
        }}
        {...rest}
      >
        {items.map((item) => {
          if ('group' in item && item.group) {
            return (
              <SubheaderMenuItem key={`${item.group}`}>
                {item.group}
              </SubheaderMenuItem>
            );
          }
          const currentItem = item as SelectItem;
          return (
            <MenuItemRoot
              key={`${currentItem.label}-${currentItem.value}`}
              value={currentItem.value}
              className={multiple ? 'multiple' : ''}
              disabled={currentItem.disabled}
              sx={{ width: autoWidth ? undefined : `${dynamicWidth}px` }}
            >
              {multiple && Array.isArray(value) && (
                <Checkbox
                  color="error"
                  checked={value.indexOf(currentItem.value) > -1}
                />
              )}
              {currentItem.label}
            </MenuItemRoot>
          );
        })}
        {clearable && !!value && (
          <MenuItemRoot key="clear" value="" className="clear-item">
            Clear selection
          </MenuItemRoot>
        )}
      </MuiSelect>
    </Root>
  );
});

Select.displayName = 'Base Select';
