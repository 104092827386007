import { useState } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import {
  IcClose,
  IcFileX,
  IcImage,
  IcNote,
  IcVideoRecorder,
} from '@/assets/images';
import { IFile } from '@/models';
import { fileSizeConverter, getFileType } from '@/utils';

import { FileListItemRoot } from './styles';
import { RenderFormField } from '..';
import { ImageViewModal } from '../Modals/ImageViewModal/ImageViewModal';

interface FileListItemProps {
  sasToken?: string;
  className?: string;
  item: IFile;
  enableEditDescription?: boolean;
  onRemove: () => void;
  index: number;
}

export const FileListItem = (props: FileListItemProps) => {
  const { className, onRemove, item, sasToken, enableEditDescription, index } =
    props;
  const { name, size, error, mimeType } = item;

  const [openImageViewModal, setOpenImageViewModal] = useState<boolean>(false);

  const renderFileIcon = () => {
    if (error) {
      return <IcFileX />;
    }
    const fileType = getFileType(mimeType);

    if (fileType === 'image') return <IcImage onClick={handleModalOpen} />;
    if (fileType === 'video') return <IcVideoRecorder />;
    return <IcNote />;
  };
  const handleModalOpen = () => {
    setOpenImageViewModal(true);
  };

  return (
    <FileListItemRoot className={className}>
      <div className="icon_box">{renderFileIcon()}</div>
      <div
        className={clsx('content_box', {
          'edit-description': enableEditDescription,
        })}
      >
        <div>
          <Typography className="txt_name">{name}</Typography>
          {!error ? (
            <Typography className="txt_size">
              {fileSizeConverter(size)}
            </Typography>
          ) : (
            <Typography className="txt_error">{error}</Typography>
          )}
        </div>
        {enableEditDescription && (
          <RenderFormField
            style={{ marginTop: '8px' }}
            name={`files.${index}.description`}
            placeholder="Description"
          />
        )}
        <IcClose
          className="icon_remove"
          onClick={onRemove}
          style={{ marginLeft: enableEditDescription ? '15px' : '' }}
        />
      </div>
      <ImageViewModal
        isOpen={openImageViewModal}
        setOpen={setOpenImageViewModal}
        item={item}
        sasToken={sasToken}
      />
    </FileListItemRoot>
  );
};
