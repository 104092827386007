import { ReactNode, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import {
  AdvancedMarker,
  AdvancedMarkerProps,
  InfoWindow,
  useAdvancedMarkerRef,
  useMap,
} from '@vis.gl/react-google-maps';

import { IcClose } from '@/assets/images';
import defaultMarkerImage from '@/assets/images/pngs/marker.png';

export interface IMarker extends Omit<AdvancedMarkerProps, 'position'> {
  key: string;
  position: google.maps.LatLngLiteral;
  icon?: ReactNode | string;
  info?: string | ReactNode;
}

interface MapMarkerProps extends IMarker {
  showInfo: boolean;
  toggleInfo: () => void;
  showContextMenu?: boolean;
}

const ContextMenu = ({
  anchorEl,
  open,
  handleMenuClose,
  toggleStreetView,
  onClick,
  selectedEvent,
}: {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleMenuClose: () => void;
  toggleStreetView: () => void;
  onClick?: (event: google.maps.MapMouseEvent) => void;
  selectedEvent: google.maps.MapMouseEvent;
}) => (
  <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
    <MenuItem
      onClick={() => {
        toggleStreetView();
        handleMenuClose();
      }}
    >
      Street View
    </MenuItem>
    <MenuItem
      onClick={() => {
        if (onClick) {
          onClick(selectedEvent);
        }
        handleMenuClose();
      }}
    >
      CFS Details
    </MenuItem>
  </Menu>
);

export const MapMarker = ({
  icon,
  info,
  showInfo,
  toggleInfo,
  onClick,
  showContextMenu,
  ...rest
}: MapMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const map = useMap();
  if (!map) return null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEvent, setSelectedEvent] =
    useState<google.maps.MapMouseEvent | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => setAnchorEl(null);

  const streetView = map.getStreetView();

  const handleMenuClick = (event: google.maps.MapMouseEvent) => {
    setSelectedEvent(event);
    streetView.setPosition({
      lat: event.latLng?.lat() as number,
      lng: event.latLng?.lng() as number,
    });
    setAnchorEl(event.domEvent.currentTarget as HTMLElement);
  };

  const toggleStreetView = () => streetView.setVisible(true);

  const handleClickMarker = (e: google.maps.MapMouseEvent) => {
    if (info) {
      toggleInfo();
    } else if (showContextMenu) {
      handleMenuClick(e);
    } else if (onClick) {
      onClick(e);
    }
  };

  const renderIcon = () => {
    if (typeof icon === 'string') {
      return <img id="marker" src={icon} style={{ maxHeight: 25 }} />;
    }
    return <div id="marker">{icon}</div>;
  };

  return (
    <>
      <AdvancedMarker ref={markerRef} onClick={handleClickMarker} {...rest}>
        {icon ? (
          renderIcon()
        ) : (
          <img id="marker" src={defaultMarkerImage} style={{ maxHeight: 25 }} />
        )}
        {selectedEvent && (
          <ContextMenu
            anchorEl={anchorEl}
            open={open}
            handleMenuClose={handleMenuClose}
            toggleStreetView={toggleStreetView}
            onClick={onClick}
            selectedEvent={selectedEvent}
          />
        )}
      </AdvancedMarker>
      {!!info && showInfo && (
        <InfoWindow anchor={marker} maxWidth={250}>
          <IconButton
            onClick={toggleInfo}
            sx={{ position: 'absolute', right: '2px', top: '2px', padding: 0 }}
          >
            <IcClose style={{ width: 15, height: 15 }} />
          </IconButton>
          {info}
        </InfoWindow>
      )}
    </>
  );
};
