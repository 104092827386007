import { FC, ReactElement } from 'react';

import {
  Card,
  Divider,
  IconButton,
  Modal,
  ModalProps,
  styled,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import { IcClose } from '@/assets/images';

export interface BaseModalProps extends Omit<ModalProps, 'children'> {
  open: boolean;
  hideCloseButton?: boolean;
  title?: string;
  classes?: {
    root?: string;
    content?: string;
    title?: string;
    divider?: string;
  };
  children?: ReactElement;
  onClose: () => void;
}

const Root = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',

  '& .modal-content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    minWidth: 600,
    padding: 20,
    borderRadius: 8,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1400,
    },

    '& .btn-close': {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.error.main,
    },
    '& .divider': {
      marginTop: 20,
      marginBottom: 20,
    },
    '& .no-divider': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  '& .fixed-width': {
    maxWidth: '600px',
  },
}));

export const AppModal: FC<BaseModalProps> = ({
  title,
  classes,
  hideCloseButton,
  onClose,
  children,
  ...rest
}) => {
  return (
    <Root onClose={onClose} className={classes?.root} {...rest}>
      <Card className={clsx('modal-content', classes?.content)}>
        <Typography
          variant="h6"
          component="h2"
          style={{ alignSelf: 'flex-start' }}
          className={classes?.title}
        >
          {!hideCloseButton && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              className="btn-close"
            >
              <IcClose />
            </IconButton>
          )}
          {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
        </Typography>

        {title && <Divider className={clsx('divider', classes?.divider)} />}
        {children}
      </Card>
    </Root>
  );
};
