import { IAddress } from './address';
import { IAgency } from './agency';
import { IAuditModel } from './base';
import { IFile } from './file';
import { IInventoryLocation } from './inventory.locations';
import { IKase, IOffense } from './kase';
import { IMasterName } from './master.names';
import { IAlcohol } from './property.alcohol';
import { ICash } from './property.cash';
import { ICreditCard } from './property.creditcard';
import { IDrug } from './property.drug';
import { IExplosive } from './property.explosive';
import { IFirearm } from './property.firearm';
import { IJewelry } from './property.jewelry';
import { IKnife } from './property.knife';
import { IUser } from './user';
import { IMasterVehicle, VehicleInvolvement, VehicleType } from './vehicle';
import { IVendor } from './vendors';

export enum PropertyTypeTag {
  PHYSICAL = 'Physical',
  VEHICLE = 'Vehicle',
  DIGITAL = 'Digital',
}

export const PropertyTypeTagList = {
  [PropertyTypeTag.PHYSICAL]: 'Physical',
  [PropertyTypeTag.VEHICLE]: 'Vehicle',
  [PropertyTypeTag.DIGITAL]: 'Digital',
} as const;

export const PropertyTypePhysical = {
  ALCOHOL: '25',
  DRUGS_NARCOTICS: '26',
  MONEY: '01',
  CREDIT_DEBIT_CARDS: '02',
  JEWELRY_PRECIOUS_METALS: '06',
  FIREARMS: '39', // Don't include "BB", pellet, or gas-powered guns
  OTHER_WEAPONS: '40',
  EXPLOSIVES: '57',
};

export const PropertyTypePhysicalList = {
  [PropertyTypePhysical.ALCOHOL]: 'Alcohol',
  [PropertyTypePhysical.CREDIT_DEBIT_CARDS]: 'Credit/Debit/Gift Card',
  [PropertyTypePhysical.EXPLOSIVES]: 'Explosives',
  [PropertyTypePhysical.MONEY]: 'Cash',
  [PropertyTypePhysical.DRUGS_NARCOTICS]: 'Drugs',
  [PropertyTypePhysical.JEWELRY_PRECIOUS_METALS]: 'Jewelry',
  [PropertyTypePhysical.OTHER_WEAPONS]: 'Knives',
  [PropertyTypePhysical.FIREARMS]: 'Firearm',
};

// -------------------------
// ===MODELS FROM BE:=======
// -------------------------

export enum PropertyType {
  // Exchange Mediums
  MONEY = '01',
  CREDIT_DEBIT_CARDS = '02',
  NEGOTIABLE_INSTRUMENTS = '03',
  // Documents
  NON_NEGOTIABLE_INSTRUMENTS = '04',
  PERSONAL_BUSINESS_DOCUMENTS = '05',
  IDENTITY_DOCUMENTS = '65',
  // Valuables
  JEWELRY_PRECIOUS_METALS = '06',
  ART_OBJECTS_ANTIQUES_OTHER_PRECIOUS_ITEMS = '07',
  // Personal Effects
  CLOTHING_FURS = '08',
  PURSES_HANDBAGS_WALLETS = '09',
  // Household items
  HOUSEHOLD_ITEMS = '10',
  // Equipment
  DRUG_NARCOTIC_EQUIPMENT = '11',
  GAMBLING_EQUIPMENT = '12',
  COMPUTER_HARDWARE_SOFTWARE = '13',
  OFFICE_EQUIPMENT = '14',
  STEREO_EQUIPMENT_TV_RADIO = '15', // Not from a Vehicle
  RECORDINGS = '16',
  SPORTS_EQUIPMENT = '17',
  PHOTOGRAPHIC_OPTICAL_EQUIPMENT = '18',
  FARM_EQUIPMENT = '19',
  HEAVY_CONSTRUCTION_INDUSTRIAL_EQUIPMENT = '20',
  BUILDING_SUPPLIES_FOR_CONSTRUCTION = '21',
  TOOLS = '22',
  ARTISTIC_SUPPLIES_ACCESSORIES = '53',
  CAMPING_HUNTING_FISHING_EQUIPMENT_OR_SUPPLIES = '54',
  CHEMICALS = '55',
  FIREARM_ACCESSORIES = '59',
  LAW_ENFORCEMENT_EQUIPMENT = '67',
  LAWN_YARD_GARDEN_EQUIPMENT = '68',
  LOGGING_EQUIPMENT = '69',
  MEDICAL_MEDICAL_LAB_EQUIPMENT = '70',
  MUSICAL_INSTRUMENTS = '72',
  PORTABLE_ELECTRONIC_COMMUNICATION_DEVICES = '75',
  MISCELLANEOUS_EQUIPMENT = '24',
  // Consumable items
  ALCOHOL = '25',
  DRUGS_NARCOTICS = '26',
  // Animals
  LIVESTOCK = '28',
  HOUSEHOLD_PETS = '29',
  // Vehicles
  AIRCRAFT = '30',
  AUTOMOBILES = '31',
  BICYCLES = '32',
  BUSES = '33',
  TRUCKS = '34',
  WATERCRAFT = '36',
  RECREATIONAL_VEHICLES = '37',
  OTHER_MOTOR_VEHICLES = '38',
  // Vehicle parts/accessories
  VEHICLE_PARTS_ACCESSORIES = '23',
  TRAILERS = '35',
  AIRCRAFT_PARTS_ACCESSORIES = '52',
  WATERCRAFT_EQUIPMENT_PARTS_ACCESSORIES = '79',
  // Weapons
  FIREARMS = '39', // Don't include "BB", pellet, or gas-powered guns
  OTHER_WEAPONS = '40',
  EXPLOSIVES = '57',
  // Structures
  STRUCTURES_SINGLE_OCCUPANCY_DWELLINGS = '41',
  STRUCTURES_OTHER_DWELLINGS = '42',
  STRUCTURES_OTHER_COMMERCIAL_BUSINESS = '43',
  STRUCTURES_OTHER_INDUSTRIAL_MANUFACTURING = '44',
  STRUCTURES_PUBLIC_COMMUNITY = '45',
  STRUCTURES_STORAGE = '46',
  STRUCTURES_OTHER = '47',
  // Other
  MERCHANDISE = '48',
  OTHER_PROPERTY = '49', // All property which cannot be assigned to any of the preceding property type
  PENDING_INVENTORY = '50', // Property description unknown until an inventory is conducted
  SPECIAL_CATEGORIES = '51',
  CROPS = '56',
  FUEL = '64',
  INTANGIBLE_IDENTITY = '66',
  NON_PRECIOUS_METALS = '71',

  NOT_APPLICABLE = '77',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export const PropertyTypeList = {
  [PropertyType.MONEY]: 'Cash',
  [PropertyType.CREDIT_DEBIT_CARDS]: 'Credit/Debit/Gift Card',
  [PropertyType.NEGOTIABLE_INSTRUMENTS]: 'Negotiable instruments',
  [PropertyType.NON_NEGOTIABLE_INSTRUMENTS]: 'Non negotiable instruments',
  [PropertyType.PERSONAL_BUSINESS_DOCUMENTS]: 'Personal business documents',
  [PropertyType.IDENTITY_DOCUMENTS]: 'Identity documents',
  [PropertyType.JEWELRY_PRECIOUS_METALS]: 'Jewelry',
  [PropertyType.ART_OBJECTS_ANTIQUES_OTHER_PRECIOUS_ITEMS]:
    'Art objects antiques other precious items',
  [PropertyType.CLOTHING_FURS]: 'Clothing furs',
  [PropertyType.PURSES_HANDBAGS_WALLETS]: 'Purses handbags wallets',
  [PropertyType.HOUSEHOLD_ITEMS]: 'Household items',
  [PropertyType.DRUG_NARCOTIC_EQUIPMENT]: 'Drug narcotic equipment',
  [PropertyType.GAMBLING_EQUIPMENT]: 'Gambling equipment',
  [PropertyType.COMPUTER_HARDWARE_SOFTWARE]: 'Computer hardware software',
  [PropertyType.OFFICE_EQUIPMENT]: 'Office equipment',
  [PropertyType.STEREO_EQUIPMENT_TV_RADIO]: 'Stereo equipment tv radio', // Not from a Vehicle
  [PropertyType.RECORDINGS]: 'Recordings',
  [PropertyType.SPORTS_EQUIPMENT]: 'Sports equipment',
  [PropertyType.PHOTOGRAPHIC_OPTICAL_EQUIPMENT]:
    'Photographic optical equipment',
  [PropertyType.FARM_EQUIPMENT]: 'Farm equipment',
  [PropertyType.HEAVY_CONSTRUCTION_INDUSTRIAL_EQUIPMENT]:
    'Heavy construction industrial equipment',
  [PropertyType.BUILDING_SUPPLIES_FOR_CONSTRUCTION]:
    'Building supplies for construction',
  [PropertyType.TOOLS]: 'Tools',
  [PropertyType.ARTISTIC_SUPPLIES_ACCESSORIES]: 'Artistic supplies accessories',
  [PropertyType.CAMPING_HUNTING_FISHING_EQUIPMENT_OR_SUPPLIES]:
    'Camping hunting fishing equipment or supplies',
  [PropertyType.CHEMICALS]: 'Chemicals',
  [PropertyType.FIREARM_ACCESSORIES]: 'Firearm accessories',
  [PropertyType.LAW_ENFORCEMENT_EQUIPMENT]: 'Law enforcement equipment',
  [PropertyType.LAWN_YARD_GARDEN_EQUIPMENT]: 'Lawn yard garden equipment',
  [PropertyType.LOGGING_EQUIPMENT]: 'Logging equipment',
  [PropertyType.MEDICAL_MEDICAL_LAB_EQUIPMENT]: 'Medical medical lab equipment',
  [PropertyType.MUSICAL_INSTRUMENTS]: 'Musical instruments',
  [PropertyType.PORTABLE_ELECTRONIC_COMMUNICATION_DEVICES]:
    'Portable electronic communication devices',
  [PropertyType.MISCELLANEOUS_EQUIPMENT]: 'Miscellaneous equipment',
  [PropertyType.ALCOHOL]: 'Alcohol',
  [PropertyType.DRUGS_NARCOTICS]: 'Drugs',
  [PropertyType.LIVESTOCK]: 'Livestock',
  [PropertyType.HOUSEHOLD_PETS]: 'Household_pets',
  [PropertyType.AIRCRAFT]: 'Aircraft',
  [PropertyType.AUTOMOBILES]: 'Automobile',
  [PropertyType.BICYCLES]: 'Bicycle',
  [PropertyType.BUSES]: 'Bus',
  [PropertyType.TRUCKS]: 'Truck',
  [PropertyType.WATERCRAFT]: 'Watercraft',
  [PropertyType.RECREATIONAL_VEHICLES]: 'Recreational vehicle',
  [PropertyType.OTHER_MOTOR_VEHICLES]: 'Other motor vehicle',
  [PropertyType.VEHICLE_PARTS_ACCESSORIES]: 'Vehicle parts accessories',
  [PropertyType.TRAILERS]: 'Trailers',
  [PropertyType.AIRCRAFT_PARTS_ACCESSORIES]: 'Aircraft parts accessories',
  [PropertyType.WATERCRAFT_EQUIPMENT_PARTS_ACCESSORIES]:
    'Watercraft equipment parts accessories',
  [PropertyType.FIREARMS]: 'Firearm',
  [PropertyType.OTHER_WEAPONS]: 'Knives',
  [PropertyType.EXPLOSIVES]: 'Explosives',
  [PropertyType.STRUCTURES_SINGLE_OCCUPANCY_DWELLINGS]:
    'Structures single occupancy dwellings',
  [PropertyType.STRUCTURES_OTHER_DWELLINGS]: 'Structures other dwellings',
  [PropertyType.STRUCTURES_OTHER_COMMERCIAL_BUSINESS]:
    'Structures other commercial business',
  [PropertyType.STRUCTURES_OTHER_INDUSTRIAL_MANUFACTURING]:
    'Structures other industrial manufacturing',
  [PropertyType.STRUCTURES_PUBLIC_COMMUNITY]: 'Structures public community',
  [PropertyType.STRUCTURES_STORAGE]: 'Structures storage',
  [PropertyType.STRUCTURES_OTHER]: 'Structures other',
  [PropertyType.MERCHANDISE]: 'Merchandise',
  [PropertyType.OTHER_PROPERTY]: 'Other_property',
  [PropertyType.PENDING_INVENTORY]: 'Pending inventory',
  [PropertyType.SPECIAL_CATEGORIES]: 'Special categories',
  [PropertyType.CROPS]: 'Crops',
  [PropertyType.FUEL]: 'Fuel',
  [PropertyType.INTANGIBLE_IDENTITY]: 'Intangible identity',
  [PropertyType.NON_PRECIOUS_METALS]: 'Non precious metals',
  [PropertyType.NOT_APPLICABLE]: 'Not applicable',
  [PropertyType.NOT_REPORTED]: 'Not reported',
  [PropertyType.UNKNOWN]: 'Unknown',
};

export const RelatedEntriesNames = {
  vehicle: [
    VehicleType.AIRCRAFT,
    VehicleType.AUTOMOBILE,
    VehicleType.BICYCLE,
    VehicleType.BUS,
    VehicleType.TRUCK,
    VehicleType.WATERCRAFT,
    VehicleType.RECREATIONAL_VEHICLE,
    VehicleType.OTHER_MOTOR_VEHICLE,
  ],
  alcohol: PropertyTypePhysical.ALCOHOL,
  creditCard: PropertyTypePhysical.CREDIT_DEBIT_CARDS,
  explosive: PropertyTypePhysical.EXPLOSIVES,
  cash: PropertyTypePhysical.MONEY,
  drugs: PropertyTypePhysical.DRUGS_NARCOTICS,
  jewelry: PropertyTypePhysical.JEWELRY_PRECIOUS_METALS,
  knife: PropertyTypePhysical.OTHER_WEAPONS,
  firearm: PropertyTypePhysical.FIREARMS,
};

export type RelatedEntryNamesType = keyof typeof VehicleType &
  keyof typeof PropertyTypePhysical;

export enum PropertyStatus {
  AUCTIONED = 'AUCTIONED',
  DESTROYED = 'DESTROYED',
  DISPOSED = 'DISPOSED',
  IN_POLICE_CUSTODY = 'IN_POLICE_CUSTODY',
  LOGGED = 'LOGGED',
  RELEASED_TO_COURT = 'RELEASED_TO_COURT',
  RELEASED_TO_LAB = 'RELEASED_TO_LAB',
  RELEASED_TO_OWNER = 'RELEASED_TO_OWNER',
  RELEASED_TO_PERSON = 'RELEASED_TO_PERSON',
  // External transfer to other agencies
  TRANSFERRED = 'TRANSFERRED',
}

export const PropertyStatusList = new Map([
  [PropertyStatus.LOGGED, 'Logged'],
  [PropertyStatus.RELEASED_TO_OWNER, 'Released to owner'],
  [PropertyStatus.RELEASED_TO_COURT, 'Released to court'],
  [PropertyStatus.RELEASED_TO_LAB, 'Released to lab'],
  [PropertyStatus.RELEASED_TO_PERSON, 'Released to person'],
  [PropertyStatus.IN_POLICE_CUSTODY, 'In police custody'],
  [PropertyStatus.DESTROYED, 'Destroyed'],
  [PropertyStatus.DISPOSED, 'Disposed'],
  [PropertyStatus.AUCTIONED, 'Auctioned'],
  // External transfer to other agencies
  [PropertyStatus.TRANSFERRED, 'Transferred'],
]);

export enum PropertyInvolvement {
  NOT_APPLICABLE = '01',
  BURNED = '02', // includes damage caused in fighting the fire - only 200-Arson
  COUNTERFEITED_FORGED = '03',
  DESTROYED_DAMAGED_VANDALIZED = '04',
  RECOVERED = '05', // to impound property which was previously reported stolen
  SEIZED = '06', // to impound property which was not previously reported stolen
  STOLEN_ETC = '07', // includes bribed, defrauded, embezzled, ransomed, robbed, etc
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export const PropertyInvolvementList: Map<string, string> = new Map([
  [
    PropertyInvolvement.NOT_APPLICABLE,
    `${[PropertyInvolvement.NOT_APPLICABLE]} = None/Not Applicable`,
  ],
  [PropertyInvolvement.BURNED, `${PropertyInvolvement.BURNED} = Burned`],
  [
    PropertyInvolvement.COUNTERFEITED_FORGED,
    `${PropertyInvolvement.COUNTERFEITED_FORGED} = Counterfeited/Forged`,
  ],
  [
    PropertyInvolvement.DESTROYED_DAMAGED_VANDALIZED,
    `${PropertyInvolvement.DESTROYED_DAMAGED_VANDALIZED} = Destroyed/Damaged/Vandalized`,
  ],
  [
    PropertyInvolvement.RECOVERED,
    `${PropertyInvolvement.RECOVERED} = Recovered`,
  ],
  [PropertyInvolvement.SEIZED, `${PropertyInvolvement.SEIZED} = Seized`],
  [
    PropertyInvolvement.STOLEN_ETC,
    `${PropertyInvolvement.STOLEN_ETC} = Stolen/etc.`,
  ],
  [
    PropertyInvolvement.NOT_REPORTED,
    `${PropertyInvolvement.NOT_REPORTED} = Not Reported`,
  ],
  [PropertyInvolvement.UNKNOWN, `${PropertyInvolvement.UNKNOWN} = Unknown`],
]);

export const PropertyInvolvementNames: Map<PropertyInvolvement, string> =
  new Map([
    [PropertyInvolvement.NOT_APPLICABLE, 'None/Not Applicable'],
    [PropertyInvolvement.BURNED, 'Burned'],
    [PropertyInvolvement.COUNTERFEITED_FORGED, 'Counterfeited/Forged'],
    [
      PropertyInvolvement.DESTROYED_DAMAGED_VANDALIZED,
      'Destroyed/Damaged/Vandalized',
    ],
    [PropertyInvolvement.RECOVERED, 'Recovered'],
    [PropertyInvolvement.SEIZED, 'Seized'],
    [PropertyInvolvement.STOLEN_ETC, 'Stolen/etc.'],
    [PropertyInvolvement.NOT_REPORTED, 'Not Reported'],
    [PropertyInvolvement.UNKNOWN, 'Unknown'],
  ]);

export const VEHICLE_PROPERTY_TYPES: PropertyType[] = [
  PropertyType.AIRCRAFT,
  PropertyType.AUTOMOBILES,
  PropertyType.BICYCLES,
  PropertyType.BUSES,
  PropertyType.TRUCKS,
  PropertyType.WATERCRAFT,
  PropertyType.RECREATIONAL_VEHICLES,
  PropertyType.OTHER_MOTOR_VEHICLES,
];

export const VEHICLE_PARTS_PROPERTY_TYPES: PropertyType[] = [
  PropertyType.VEHICLE_PARTS_ACCESSORIES,
  PropertyType.TRAILERS,
  PropertyType.AIRCRAFT_PARTS_ACCESSORIES,
  PropertyType.WATERCRAFT_EQUIPMENT_PARTS_ACCESSORIES,
];

export interface IPropertyLoggedInfo {
  loggedBy?: IUser;
  loggedDate?: Date;
  loggedAddress?: IAddress;
  isLoggedAtKaseAddress?: boolean;
}

export interface IInboundTransferredInfo {
  transferredBy?: IUser;
  transferredDate?: Date;
}

export enum PropertyVehicleInvolvement {
  ABANDONED = 'ABANDONED',
  ACCIDENT = 'ACCIDENT',
  ARREST = 'ARREST',
  IMPOUNDED = 'IMPOUNDED',
  TOWED = 'TOWED',
}

export interface IPropertyVehicle {
  dataSourceVerified?: boolean;
  masterVehicle?: IMasterVehicle;
  involvement?: PropertyVehicleInvolvement;
  // Towing company type vendor
  vendor?: IVendor;
  parkingSpotNumber?: string;
  impoundLot?: string;
}
export interface IPropertyVehicleForm
  extends Omit<IPropertyVehicle, 'masterVehicle' | 'vendor'> {
  masterVehicle?: string;
  vendor?: string;
}

export interface IPropertyVehicleParts {
  inventoryLocation?: IInventoryLocation;
}
export interface relatedBase {
  notes?: string;
}
export interface IPropertyRelatedOffenses extends relatedBase {
  offenses?: IOffense[];
}

export interface IPropertyRelatedMasterNames extends relatedBase {
  masterNames?: IMasterName[];
}

export interface IPropertyRelatedMasterVehicles extends relatedBase {
  masterVehicles?: IMasterVehicle[];
}

export interface IBaseCustodyActionResultInfo {
  notes?: string;
  updatedAt?: Date;
  updatedBy?: IUser;
}

interface IBasePropertyReleaseInfo {
  expectedBackDate?: Date;
  signature?: string;
}

export interface IReleasedToLabInfo
  extends IBasePropertyReleaseInfo,
    IBaseCustodyActionResultInfo {
  // Lab type vendor
  vendor?: IVendor;
  trackingNumber?: string;
}

export interface IReleasedToCourtInfo
  extends IBasePropertyReleaseInfo,
    IBaseCustodyActionResultInfo {
  // Court type vendor
  vendor?: IVendor;
  trackingNumber?: string;
}

export interface IReleasedToPersonInfo
  extends IBasePropertyReleaseInfo,
    IBaseCustodyActionResultInfo {
  // user of current agency
  user?: IUser;
  // masterName person involved in this incident
  masterName?: IMasterName;
}

// transfer to other agencies
export interface IOutboundTransferredInfo
  extends IBaseCustodyActionResultInfo,
    IBasePropertyReleaseInfo {
  agency: IAgency;
  user: IUser;
  trackingNumber?: string;
}

export type ICustodyActionResultInfo =
  | IBaseCustodyActionResultInfo
  | IReleasedToLabInfo
  | IReleasedToCourtInfo
  | IReleasedToPersonInfo
  | IOutboundTransferredInfo;

export interface IPropertyTypes {
  alcohol?: IAlcohol;
  creditCard?: ICreditCard;
  explosive?: IExplosive;
  cash?: ICash;
  drugs?: IDrug[];
  jewelry?: IJewelry;
  knife?: IKnife;
  firearm?: IFirearm;
  vehicle?: IPropertyVehicle;
}

export interface IProperty extends IAuditModel, IPropertyTypes {
  kaseId: IKase;
  number: string;
  name?: string;
  description?: string;
  serialNumber?: string;
  // property value as USD
  value?: number;
  typeTag: PropertyTypeTag;
  type?: PropertyType;
  involvement?: PropertyInvolvement;
  recoveryDate?: Date;
  createdBy: IUser;
  loggedInfo?: IPropertyLoggedInfo;
  owner?: IMasterName;
  status: PropertyStatus;
  targetDispositionDate?: string;
  files?: IFile[];
  labResults?: IFile[];
  droppedOffLocation?: IInventoryLocation;
  digitalFile?: IFile;
  // vehicle parts/accessories
  vehicleParts?: IPropertyVehicleParts;
  // transfer inside agency
  inboundTransferredInfo?: IInboundTransferredInfo;
  isEvidence?: boolean;
  isBiohazard?: boolean;
  relatedOffenses?: IPropertyRelatedOffenses;
  relatedMasterNames?: IPropertyRelatedMasterNames;
  relatedMasterVehicles?: IPropertyRelatedMasterVehicles;
  custodyActionResultInfo?: ICustodyActionResultInfo;
}

export interface IPropertyForm
  extends Omit<
    IProperty,
    | 'kaseId'
    | 'owner'
    | 'firearm'
    | 'knife'
    | 'alcohol'
    | 'inboundTransferredInfo'
    | 'relatedMasterNames'
    | 'relatedMasterVehicles'
    | 'relatedOffenses'
    | 'loggedInfo'
    | 'vehicle'
    | 'droppedOffLocation'
  > {
  address?: IAddress;
  owner?: string;
  propertyTypeDetails: keyof typeof PropertyTypePhysicalList;
  vehicleInvolvement: keyof typeof VehicleInvolvement;
  droppedOffLocation: string;
  inPoliceCustody?: boolean;
  loggedInfo: {
    loggedBy?: string;
    loggedDate?: Date;
    loggedAddress?: IAddress;
    isLoggedAtKaseAddress?: boolean;
  };
  inboundTransferredInfo?: {
    transferredBy?: string;
  };
  firearm?: {
    model: string;
    make: string;
  };
  knife?: {
    model: string;
    make: string;
  };
  alcohol?: {
    brand: string;
  };
  relatedMasterNames?: {
    notes: string;
    masterNames: string[];
  };
  relatedMasterVehicles?: {
    notes: string;
    masterVehicles: string[];
  };
  relatedOffenses?: {
    notes: string;
    offenses: string[];
  };
  vehicle: IPropertyVehicleForm;
}

export interface IPropertyRequest extends Omit<IPropertyForm, 'loggedInfo'> {
  loggedInfo: {
    loggedBy?: string;
    loggedDate?: Date;
    loggedAddress?: string;
    isLoggedAtKaseAddress?: boolean;
  };
}
